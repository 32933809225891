export default [
    {
        title:"代取广场",
        path:"/issueExpress",
        url:require("$icon/Basics/menusExpress.png"),
        code:"dqkd",
    },
    {
        title:"零食铺子",
        path:"/allShop",
        url:require("$icon/Basics/menusMeal.png"),
        code:"lspz",
    },
    {
        title:"校园外卖",
        path:"/allShop",
        url:require("$icon/Basics/menusNoodles.png"),
        code:"xywm",
    },
    {
        title:"校园百货",
        path:"/allShop",
        url:require("$icon/Basics/menusActivity.png"),
        code:"xybh",
    },
    {
        title:"新鲜水果",
        path:"/allShop",
        url:require("$icon/Basics/MenusFruits.png"),
        code:"xxsg"
    },
    {
        title:"万能互助",
        path:"/allHelp",
        url:require("$icon/Basics/menusHelp.png"),
        code:"wnhz",
    },
    {
        title:"我的地址",
        path:"/addressList",
        url:require("$icon/Basics/menusAddress.png"),
        code:"",
    },
    {
        title:"认证中心",
        path:"/authentication",
        url:require("$icon/Basics/MenusAuthentication.png"),
        code:"",
    },
    {
        title:"我的帮助",
        path:"/about",
        url:require("$icon/Basics/menusMore.png"),
        code:"bzzx",
    },
]