




































































import { Toast } from "vant"
import { Component, Vue, Watch } from "vue-property-decorator";
import { IndexStore } from "./../store/modules/Index";
import { HomeStore } from "../store/modules/Home";
import { GetNoticeNum, GetBanner, GetNotice } from "../Api/Basics/index";
import Stroage from "../util/Storage";
import { Notify } from "vant";
import SearchCom from "../components/ShareComponent/Search.vue";
import Menus from "../components/HomeComponent/Menus.vue";
import Link from "../components/HomeComponent/Link.vue";
import { NoticeType } from "../Type/index";
import ZoomPage from "@/impView/PageSafety";

interface HomeType {
  BellIcon: any;
  title: string;
  token?: string;
  userId?: number | string;
  number: number;
  ImgList: Array<string>;
  Loading: boolean;
  NoticeIcon: string;
  NoticeList: Array<NoticeType>;
  init(): void;
  handleToNotice(): void;
  handleUpData(): void;
  handleUpBanner(): void;
  handleFilterImgList(data: Array<string>): void;
  handleUpNotice(): void;
  handleToNoticeSuc(data: NoticeType): void;
  handleError(data: string): void;
}

@Component({
  name: "Home",
  components: {
    SearchCom,
    Menus,
    Link,
  },
})
export default class Home extends ZoomPage implements HomeType {
  BellIcon = require("$icon/Basics/bell.png");
  title = "请先登录";
  userId?: string | number;
  token?: string;
  number = 0;
  ImgList = [require("$img/Banner.png")];
  Loading = false;
  NoticeIcon = require("$icon/Basics/notice.png");
  NoticeList = [
    {
      content:
        " 全国计算机等级考试简称（NCRE），是经教育部批准，由教育部考试中心主办，用于考查应试人员计算机应用知识与能力的全国性计算机水平考试体系。为了便于考生报名",
    },
  ];

  mounted() {
    if (!IndexStore.GetLoading) return;
    this.userId = Stroage.GetData_ && Stroage.GetData_("userId");
    this.token = Stroage.GetData_ && Stroage.GetData_("token");
    this.title =
      (Stroage.GetData_ && JSON.parse(Stroage.GetData_("UserData")).schoolName) ||
      "请先登录";
    this.number = HomeStore.GetNoticeNum;
    if( HomeStore.GetBannerList.length ){
      this.ImgList = HomeStore.GetBannerList
    }else{
      this.handleUpBanner();
    }
    if( HomeStore.GetNoticeList.length ){
      this.NoticeList = HomeStore.GetNoticeList
    }else{
      this.handleUpNotice();
    }
  }

  init() {
    this.Loading = true;
    this.userId = Stroage.GetData_ && Stroage.GetData_("userId");
    this.token = Stroage.GetData_ && Stroage.GetData_("token");
    this.title =
      (Stroage.GetData_ && JSON.parse(Stroage.GetData_("UserData")).schoolName) ||
      "请先登录";
    this.handleUpData();
    if( HomeStore.GetBannerList.length ){
      this.ImgList = HomeStore.GetBannerList
    }else{
      this.handleUpBanner();
    }

    if( HomeStore.GetNoticeList.length ){
      this.NoticeList = HomeStore.GetNoticeList
    }else{
      this.handleUpNotice();
    }
  }

  handleToNotice() {
    if (!IndexStore.GetLoading) {
      Toast({
      message:"请先登录",
      icon:"none",
      className:"ToastClassName"
      })
      return;
    }
    this.$router.push({
      path: "/informList",
    });
  }

  handleUpData() {
    this.Loading = true
    GetNoticeNum(
      {
        userId: this.userId as string,
        token: this.token as string,
      },
      {
        pageNo: 1,
        pageSize: 100,
        type: 0,
      }
    ).then((res: any) => {
      this.Loading = false
      if (res.message.code === "200") {
        this.number = res.data.number | 0;
        res.data.number && HomeStore.SetNoticeNum(res.data.number);
      } else {
        this.handleError(res.message.msg);
      }
    });
  }

  handleUpBanner() {
    this.Loading = true
    GetBanner({
      userId: this.userId as string,
      token: this.token as string,
    }).then((res: any) => {
      this.Loading = false
      if (res.message.code === "200") {
        this.handleFilterImgList(res.data.bannerList);
      } else {
        this.handleError(res.message.msg);
      }
    });
  }

  handleFilterImgList(List: Array<string>) {
    if (List.length) {
      let newList: Array<string> = [];
      List.forEach((item: any, index: any) => {
        newList.push(item.imageUrl);
      });

      this.ImgList = newList;
      HomeStore.SetBannerList(newList as Array<string>);
    }
  }

  handleUpNotice() {
    this.Loading = true
    GetNotice(
      {
        userId: this.userId as string,
        token: this.token as string,
      },
      {
        type: 0,
        pageNo: 1,
        pageSize: 15,
      }
    ).then((res: any) => {
      this.Loading = false
      if (res.message.code === "200") {
        this.NoticeList = res.data.list;
        HomeStore.SetNoticeList(res.data.list);
      } else {
        this.handleError(res.message.msg);
      }
    });
  }

  handleToNoticeSuc(data: NoticeType) {
    if (!IndexStore.GetLoading) {
      Toast({
      message:"请先登录",
      icon:"none",
      className:"ToastClassName"
      })
      return;
    }
    if (Boolean(data.id)) {
      this.$router.push({
        path: "/informSuc",
        query: {
          id: data.id as string,
        },
      });
    }
  }

  handleError(data: string) {
    let str = "";
    if (data.length) {
      str = data;
    } else {
      str = "网络繁忙...";
    }
    Notify({
      message: str,
      color: "white",
      background: "rgba(0,0,0,.6)",
    });
  }

  get GetLoading() {
    return IndexStore.GetLoading;
  }

  @Watch("GetLoading")
  handleChange(newval: any) {
    if (newval) {
      this.init();
    }
  }


}
