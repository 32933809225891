

























import { Toast } from "vant"
import { IndexStore } from "@/store/modules/Index"
import { Vue, Component } from "vue-property-decorator";
interface ListItemTyoe<T> {
  img: T;
  title: T;
  content: T;
  path: T;
}

interface LinkType {
  List: Array<ListItemTyoe<string>>;
  handleToPath(path?: string): void;
}

@Component({ name: "Link" })
export default class Link extends Vue implements LinkType {
  List = [
    {
      img: require("../../assets/icon/Basics/video.png"),
      title: "课程表",
      content: "校园课程表",
      path: "/allClass",
    },
    {
      img: require("../../assets/icon/Basics/secondHand.png"),
      title: "二手交易",
      content: "闲置物品快速出手",
      path: "/allHand",
    },
  ];

  handleToPath(path?: string) {
    if (!IndexStore.GetLoading) {
      Toast({
      message:"请先登录",
      icon:"none",
      className:"ToastClassName"
      })
      return;
    }
    if (typeof path === "string") {
      this.$router.push({
        path,
      });
    }
  }

  get GetLoading() {
      return IndexStore.GetLoading;
  }
}
