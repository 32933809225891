

















import { Notify,Toast } from "vant"
import { IndexStore } from "../../store/modules/Index"
import { HomeStore } from "@/store/modules/Home"
import { MenusType as MenusTypeT } from "@/Type/index"
import { UserStore } from "@/store/modules/User"
import { CouponStore } from "@/store/modules/Basics/Coupon"
import MenusData from "@/util/MenusData"
import Stroage from "@/util/Storage"
import { GetModule } from "@/Api/Basics/index"
import { Vue,Component,Watch } from "vue-property-decorator"

interface MenusType{
    loadingShow:boolean
    List:Array<MenusTypeT>
    userId:string|number;
    token:string;

    initData():void;
    handleToPath(path?:MenusTypeT,index?:number):void;
    handleUpData():void;
    handleFilterList(data:Array<MenusTypeT>):void;
    handleError(data:string):void;
}


 @Component({ name:"Menus" })
 export default class Menus extends Vue implements MenusType{
    loadingShow = false
    userId:string|number = ""
    token = ""
    List:Array<MenusTypeT> = [ 
        { title:"代取广场", path:"/issueExpress", url:require("$icon/Basics/menusExpress.png"), code:"dqkd", },
        { title:"零食铺子", path:"/allShop", url:require("$icon/Basics/menusMeal.png"), code:"lspz", },
        { title:"校园外卖", path:"/allShop", url:require("$icon/Basics/menusNoodles.png"), code:"xywm", },
        { title:"校园百货", path:"/allShop", url:require("$icon/Basics/menusActivity.png"), code:"xybh", },
        { title:"万能互助", path:"/allHelp", url:require("$icon/Basics/menusHelp.png"), code:"wnhz", },
        { title:"我的地址", path:"/addressList", url:require("$icon/Basics/menusAddress.png"), code:"", },
        { title:"认证中心", path:"/authentication", url:require("$icon/Basics/MenusAuthentication.png"), code:"", },
        { title:"我的帮助", path:"/about", url:require("$icon/Basics/menusMore.png"), code:"bzzx", },
     ]

    mounted() {
        if (!IndexStore.GetLoading) return;
        this.initData()
    }

    initData(){
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        if( HomeStore.GetMenusList.length ){
            this.List = HomeStore.GetMenusList //as Array<MenusTypeT>
        }else{
            this.handleUpData()
        }
    }

    handleToPath( data:MenusTypeT<string>,index:number ){
        if (!IndexStore.GetLoading) {
            Toast({
            message:"请先登录",
            icon:"none",
            className:"ToastClassName"
            })
            return;
        }
        switch( data.code ){
            case "dqkd":
                UserStore.SetSelAddress({})
                CouponStore.SetSelCoupon({})
                break;
            case "lspz": // 零食铺子
                IndexStore.SetShopState({
                    state:index,
                    title:data.name || '零食铺子',
                    type:2
                })
                CouponStore.SetSelCoupon({})
                break;
            case "xywm": // 外卖
                IndexStore.SetShopState({
                    state:index,
                    title:data.name || '校园外卖',
                    type:0
                })
                CouponStore.SetSelCoupon({})
                break;
            case "xybh": // 百货
                IndexStore.SetShopState({
                    state:index,
                    title:data.name || '校园外卖',
                    type:1
                })
                CouponStore.SetSelCoupon({})
                break;
            case "xxsg": // 新鲜水果
                IndexStore.SetShopState({
                    state:index,
                    title:data.name || '新鲜水果',
                    type:3
                })
                CouponStore.SetSelCoupon({})
                break;
            case "wnhz":
                UserStore.SetSelAddress({})
                    break;
        }
        this.$router.push({
            path:data.path as string
        })
    }

    handleUpData(){
        GetModule({
            userId:this.userId,
            token:this.token
        },{
            pageNo:1,
            pageSize:1000
        }).then(res=>{
            console.log( res )
            if( res.message.code === "200" ){
                this.handleFilterList( res.data )
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handleFilterList(data:Array<MenusTypeT>){
        let newList:Array<MenusTypeT> = []
        MenusData.forEach((item,index)=>{
            let Index = data.findIndex( (a)=>a.code == item.code )
            if ( Index >= 0 ){
                if ( data[ Index ]["status"]! > 0){
                    newList.push({
                        ...item,
                        ...data[ Index ]
                    })
                }
            }
        })
        newList = newList.sort((a,b)=>Number(a["sort"] ) - Number( b["sort"] ) )
        let Num = 8 - newList.length
        if ( Num ){
            switch ( Num ) {
                case 1:
                    newList.splice( newList.length - 1,0,MenusData[7] )
                    break;
                case 2:
                    newList.splice( newList.length - 1,0,MenusData[6] )
                    newList.splice( newList.length - 1,0,MenusData[7] )
                    break
            }
        }
        this.List = newList
        HomeStore.SetMenusList( newList )
    }

    handleError( data:string ){
        let str = ""
        if ( data.length ){
            str = data
        }else{
            str = "网络繁忙..."
        }
        Notify({
            message: str,
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });            
    }

    get GetLoading() {
        return IndexStore.GetLoading;
    }

    @Watch("GetLoading")
    handleChange(newval: any) {
        if (newval) {
            this.initData();
        }
    }
 }
